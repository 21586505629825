<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        <small class="q-ml-sm text-size-sm"> </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <select_hospInfo
          v-if="IsHospID == 0"
          :dense="true"
          outlined
          class="col-11"
          label="选择机构"
          v-model="PdmBodycheckFenleiEntity.HospID"
          v-model:valueCode="PdmBodycheckFenleiEntity.HospID"
        />
        <q-input
          label="分类名称"
          dense
          outlined
          class="col-8"
          v-model="PdmBodycheckFenleiEntity.FenLei_Name"
        />
        <q-input
          label="排序"
          dense
          outlined
          class="col-3"
          v-model="PdmBodycheckFenleiEntity.FenLei_Sort"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="确定" @click="onOK" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import select_hospInfo from "@/views/pdm/comm/hospInfo_select.vue";
export default {
  components: {
    select_hospInfo,
  },
  props: {},
  computed: {
    ...mapState("PdmBodycheckFenlei", [
      "PdmBodycheckFenleiShowDlg",
      "PdmBodycheckFenleiEntity",
      "PdmBodycheckFenleiList",
    ]),
  },
  data() {
    return {
      IsHospID: 0,
    };
  },
  mounted() {
   
    this.IsHospID = this.$store.getters["appUser/hospID"];
  },
  methods: {
    ...mapActions("PdmBodycheckFenlei", [
      "actUpdatePdmBodycheckFenlei",
      "actGetPdmBodycheckFenleiList",
    ]),
    onOK() {
      this.actUpdatePdmBodycheckFenlei({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmBodycheckFenleiEntity,
      }).then((res) => {
        if (res.Code == 0) {
          //setTimeout(()=> {
          //this.$emit("onLoadData");
          //}, 500);
          this.$emit("onLoadData");
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
