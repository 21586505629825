<template>
    <div class="absolute-center">
         <!-- <q-btn color="primary" label="打开微信小程序" @click="onOpen" /> -->
         <div class="text-h6">
            正在跳转，请稍后...
         </div>
    </div>
</template>

<script>
import * as wxapi from "@/api/pdm/wx.js";
import dlg from '@/config/dialog'
export default {
    components: {
       
    },
    data() {
        return{
            queryHospID:null,
            QueryID:0,//用来判断跳转小程序哪个页面:0首页，1自助缴费
            QueryType:1,//类别：1门诊，2住院
            openid:null,//微信用户的openid
            DeptHisCode:null,//科室his编码
        }
    },
    created(){
        this.queryHospID = this.$route.query.HospID;
        this.QueryID = this.$route.query.QueryID;
        this.QueryType=this.$route.query.QueryType;
        this.openid=this.$route.query.openid;
        this.DeptHisCode=this.$route.query.DeptHisCode;
        this.onOpen()
    },
    methods: {
        onOpen() {
            //dlg.alert(this.queryHospID)
            wxapi.GetWX_UrlScheme_Generate({
                HospID:this.queryHospID,
                QueryID:this.QueryID,
                QueryType:this.QueryType,
                openid:this.openid,
                DeptHisCode:this.DeptHisCode
            }).then(res=>{
                if (res.Code==0) {
                    location.href = res.Data;
                }else{
                    dlg.alert(res.Message)
                }
            })
        },
    },
};
</script>
