<template>
  <router-view />
</template>

<script>
// import { LogInByHisUserCode } from "@/api/qdmi/miInfoColl/miEmpInfo";
export default {
  data() {
    return {
      Uid: "",
      HospID: "",
    };
  },
  beforeCreate() {
    
  },
};
</script>