<template>
    <div>
        <MaAppt />
    </div>
</template>

<script>
import MaAppt from "./maApptView.vue";
export default {
    components: {
        MaAppt,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
