import { axios } from '@/utils/request'

const api = {
    _service: "/WX"
}

export default api


//-------------------获取小程序外部链接的 URL Scheme 码-------------------
export function GetWX_UrlScheme_Generate(parameter) {
    return axios({
        url: api._service + "/GetWX_UrlScheme_Generate",
        method: 'post',
        data: parameter
    })
}

/**
 * 
 * 获取微信服务号的Code
 */
export function GetWXFuWuHaoCode(parameter) {
    return axios({
        url: api._service + "/GetWXFuWuHaoCode",
        method: 'post',
        data: parameter
    })
}

/**
 * 获取微信服务号的openid
 */
export function GetWXFuWuHaoOpenID(parameter) {
    return axios({
        url: api._service + "/GetWXFuWuHaoOpenID",
        method: 'post',
        data: parameter
    })
}
