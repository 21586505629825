<!-- 信息统计栏 -->
<template>
  <q-card :class="cardClass" class="defaultBackground q-mr-sm q-my-sm ">
    <q-card-section horizontal class="q-pr-sm q-my-sm">
      <q-card-section class="col-4 flex flex-center">
        <q-icon size="md" :name="icon" />
      </q-card-section>

      <q-card-section v-if="!typeFlag" class="q-pa-sm">
        <div class="titleClass">{{ title }}</div>
        <div class="valueClass q-mt-xs q-mb-xs">
          {{ value }}
          <small class="text-overline">{{ unit }}</small>
        </div>
      </q-card-section>
      <q-card-section v-else class="q-pa-sm" style="width:300px;"> 
        <div class="titleClass">{{ title }}</div>
        <div class="row q-pa-sm">
          <div class="col-6" >
            <div class="row ">
              <div class="titleClass text-overline col-4">
               {{ unit }}
              </div>
              <div class="valueClass col-6">
                 {{ value }}
               
              </div>
            </div>
          </div>

          <div class="col-6 ">
            <div class="row">
              <!-- style="margin-top: -4.35rem;margin-left: 5rem" -->
              <div class="titleClass text-overline col-4">
                {{ unit2 }}
              </div>
              <div class="valueClass col-6">
                {{ value2 }} 
                
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "fas fa-home",
    },
    cardClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "统计标题",
    },
    value: {
      type: Number ,
      default: 123456.00,
    },
    value2: {
      type: Number,
      default: 123456.00,
    },
    unit: {
      type: String,
      default: "元",
    },
    unit2: {
      type: String,
      default: "元",
    },
    typeFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
.valueClass {
  font-size: 24px;
  font-family: Bahnschrift;
  font-weight: 400;
  line-height: 30px;
}

.titleClass {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 30px;
}

.defaultBackground {
  color: white;
  background: linear-gradient(-86deg, #2c76ff, #47abff);
  box-shadow: 0px 7px 7px 0px rgba(62, 154, 255, 0.28);
  border-radius: 6px;
}
</style>