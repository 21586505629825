<template>
    <div>
        <BodycheckFenlei />
    </div>
</template>

<script>
import BodycheckFenlei from "./bodycheckFenleiView.vue";
export default {
    components: {
        BodycheckFenlei,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
