<template>
  <q-layout view="hHh LpR fFf" class="bg-grey-2">
    <q-header class="bg-white ForegroundColor" height-hint="50">
      <q-toolbar dense>
        <q-btn dense flat round v-if="currentSystem" :icon="currentSystem.Icon ? currentSystem.Icon : 'menu'"
          @click="toggleLeftDrawer" color="primary" />
        <q-toolbar-title v-if="currentSystem" shrink style="min-width: 150px">
          {{ currentSystem.SysName }}
        </q-toolbar-title>
        <MultiPageBar />
        <q-space />
        <div style="height: 38px">
          <ToolbarRight @toggleRightDrawer="toggleRightDrawer" />
        </div>
      </q-toolbar>
    </q-header>

    <!-- 左侧抽屉功能 -->
    <q-drawer show-if-above v-model="leftDrawer" :width="200" side="left" class="bg-grey-2" style="padding-top:10px">
      <SlideMenu />
    </q-drawer>

    <!-- 右侧抽屉功能 -->
    <q-drawer show-if-above v-model="rightDrawerOpen" side="right" overlay bordered behavior="mobile">
      <DrawerUserInfo />
    </q-drawer>
    <!-- 页面router-view -->
    <q-page-container>
      <q-page>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" :key="$route.name" />
          </keep-alive>
        </router-view>
      </q-page>
    </q-page-container>

    <q-footer class="bg-grey-2 text-grey-8" style="max-height:35px">
      <q-toolbar>
        <q-toolbar-title>
          <div>
            <span class="text-subtitle2 q-mr-md">{{ appSettings.AppName }}</span>
            <span class="text-overline">{{ appSettings.AppVerison }}</span>
          </div>
        </q-toolbar-title>
        <span class="text-subtitle2 q-mr-md">{{ appSettings.AppSupplier }}</span>
        <span class="text-overline q-mr-md">{{ appSettings.CopyRight }}</span>
      </q-toolbar>
    </q-footer>
    <q-ajax-bar ref="bar" position="bottom" color="accent" size="10px" skip-hijack />
  </q-layout>
</template>

<script>
import SlideMenu from "./menu/slideMenu";
import ToolbarRight from "./header/toolbarRight";
import DrawerUserInfo from "./rightDrawer/sysUserInfo";
import MultiPageBar from "./multiPage/multiPageBar.vue";
import { mapGetters, mapState } from "vuex";
import { useMeta } from "quasar";

export default {
  name: "workplace",
  components: {
    SlideMenu,
    ToolbarRight,
    DrawerUserInfo,
    MultiPageBar,
  },

  computed: {
    ...mapGetters(["appSettings"]),
    ...mapGetters("appUser", ["authModules"]),
    ...mapState("MultiPageBar", ["PageList", "CurrentPage"]),

    leftDrawer: {
      get() {
        return this.$store.getters["appSetting/leftDrawer"]
      },
      set(val) {
        this.$store.commit("appSetting/setLeftDrawer", val);
      },
    },
    keepRoutes() {
      var rs = [];
      this.PageList.forEach((e) => {
        rs.push(e.name);
      });
      return rs;
    },
    btnToggleOptions() {
      var opt = [];
      this.currentSysModules.forEach((e) => {
        var v = {
          label: e.ModelName,
          icon: e.RouteIcon,
          value: e.ModuleID,
        };
        opt.push(v);
      });
      return opt;
    },
    currentSysModules() {
      var mods = [];
      if (this.currentSystem)
        mods = this.currentSystem.Children.filter((v) => {
          return !v.IsHide;
        });
      return mods;
    },

    currentSystem() {
      var t = this.authModules.find((v) => {
        return v.SysID == this.SystemKey;
      });
      return t;
    },
  },
  watch: {
    currentSysID(val) {
      this.$store.dispatch("appUser/actSetSysID", val);
    },
  },
  data() {
    return {
      //leftDrawerOpen: true,
      rightDrawerOpen: false,
      currentSysID: "0",
    };
  },
  mounted() {
    // if (LocalStorage.has("LeftDrawer"))
    //   this.leftDrawerOpen = LocalStorage.getItem("LeftDrawer");

    this.currentSysID = this.$store.getters["appUser/currentSysID"] + "";

    this.$nextTick(() => {
      this.rightDrawerOpen = false;
      if (this.currentSysID == "0") {
        this.currentSysID =
          this.currentSysModules.length > 0
            ? this.currentSysModules[0].ModuleID
            : "";
      }
    });

    const metaData = {
      // sets document title
      title: "青岛万斯迈智慧医疗管理平台",
      // optional; sets final title as "Index Page - My Website", useful for multiple level meta
      titleTemplate: (title) => `${title} `,
    };
    useMeta(metaData);
  },
  methods: {
    toggleLeftDrawer() {
      console.log(this.leftDrawer)
      // this.leftDrawerOpen = !this.leftDrawerOpen;
      // LocalStorage.set("LeftDrawer", this.leftDrawerOpen);
      if (!this.leftDrawer) this.leftDrawer = false
      this.leftDrawer = !this.leftDrawer
    },

    toggleRightDrawer() {
      this.rightDrawerOpen = !this.rightDrawerOpen;
    },


    onMenuClick(e) {
      this.currentSysID = e.ModuleID;
    },
  },
};
</script>


<style scoped>
.ForegroundColor {
  color: #333333
}

.ForegroundSelectionColor {
  color: white
}
</style>