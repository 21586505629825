<template>
  <q-list v-if="currentModules">
    <span v-for="d in currentModules" :key="d.ModuleID">
      <SlideMenuItem :dataObject="d" v-if="!d.IsHide" />
    </span>
  </q-list>
</template>

<script>
import { mapGetters } from "vuex";
import SlideMenuItem from "./slideMenuItem";
export default {
  name: "slideMenu",
  components: {
    SlideMenuItem,
  },
  computed: {
    ...mapGetters("appUser", ["authModules", "currentSysID"]),
  },
  watch: {
    currentSysID: {
      handler() {
        this.onLoadData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      currentModules: [],
    };
  },
  created() {
    this.onLoadData();
  },
  methods: {
    onLoadData() {
      if (this.authModules) {
        var mod = this.authModules.find((v) => {
          return v.SysID == this.SystemKey && v.IsHide == false;
        });
        this.currentModules = mod.Children
      }
    },
  },
};
</script>

<style scoped>
.Background {
  background-color: #f3f3f7;
}
</style>