<template>
    <div>
        <Banner />
    </div>
</template>

<script>
import Banner from "./bannerView.vue";
export default {
    components: {
        Banner,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
