<template>
    <div class="absolute-center">
         <!-- <q-btn color="primary" label="打开微信小程序" @click="onOpen" /> -->
         <div class="text-h6">
            正在跳转，请稍后...
         </div>
    </div>
</template>

<script>
import * as wxapi from "@/api/pdm/wx.js";
import dlg from '@/config/dialog'
export default {
    components: {
       
    },
    data() {
        return{
            QueryHospID:null,
            QueryID:0,//用来判断跳转小程序哪个页面:0首页，1自助缴费
            QueryType:1,//类别：1门诊，2住院
        }
    },
    created(){
        this.QueryHospID = this.$route.query.HospID;
        this.QueryID = this.$route.query.QueryID;
        this.QueryType=this.$route.query.QueryType;
        this.onOpen()
    },
    methods: {
        onOpen() {
            //dlg.alert(this.queryHospID)
            wxapi.GetWXFuWuHaoCode({
                HospID:this.QueryHospID,
                QueryID:this.QueryID,
                QueryType:this.QueryType,
            }).then(res=>{
                if (res.Code==0) {
                    window.location.href=res.Data;
                }else{
                    dlg.alert(res.Message)
                }
            })
        },
    },
};
</script>
