<template>
  <q-card flat>
    <q-item>
      <q-item-section avatar>
        <q-avatar>
          <q-icon
            class="glossy"
            color="primary"
            size="30px"
            name="account_box"
          />
        </q-avatar>
      </q-item-section>

      <q-item-section>
        <q-item-label>用户管理</q-item-label>
        <q-item-label caption> 管理维护系统操作用的基本信息</q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-btn
          dense
          class="q-mr-md"
          color="primary"
          icon="note_add"
          @click="onAdd"
          >新增</q-btn
        >
      </q-item-section>
    </q-item>
    <q-separator />
    <q-card-section>
      <div class="row q-ma-xs">
        <div style="width: 30vw" class="q-mr-md">
          <q-input dense label="姓名\手机号\邮箱">
            <template v-slot:append>
              <q-btn
                dense
                flat
                color="primary"
                class="q-mr-md"
                icon="search"
                @click="onLoadData"
                >查询</q-btn
              >
            </template>
          </q-input>
        </div>
      </div>
      <vxe-table
        size="mini"
        :data="UserList"
        border
        stripe
        resizable
        highlight-current-row
        highlight-hover-row
        :loading="loading"
        :max-height="$store.getters.maxPageHeight - 146"
      >
        <vxe-column
          align="center"
          type="seq"
          title="序号"
          width="60"
          fixed="left"
        ></vxe-column>
        <vxe-column
          field="UserCode"
          title="用户编码"
          width="180"
          align="left"
        />
        <vxe-column
          field="UserName"
          title="用户姓名"
          min-width="120"
          max-width="180"
        />
        <vxe-column
          field="Sex"
          title="性别"
          min-width="80"
          width="160"
          align="center"
        >
          <template #default="{row}">
            <span v-if="row.Sex == 1">男</span>
            <span v-if="row.Sex == 2">女</span>
          </template>
        </vxe-column>
        <vxe-column field="Phone" title="手机" min-width="120" width="260" />
        <vxe-column field="Email" title="邮箱" min-width="180" />
        <vxe-column
          field="Status"
          title="帐号状态"
          min-width="80"
          width="160"
          align="center"
        >
          <template #default="{row}">
            <span v-if="row.Status == 1">启用</span>
            <span v-if="row.Status == 0">禁用</span>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="300" fixed="right">
          <template #default="{ row }">
            <q-btn
              flat
              dense
              color="primary"
              icon="edit"
              class="q-mr-sm"
              @click="onEdit(row)"
              >修改</q-btn
            >
            <q-btn
              flat
              dense
              color="negative"
              icon="delete"
              class="q-mr-sm"
              @click="onDelete(row)"
              >删除</q-btn
            >
            <q-btn
              flat
              dense
              color="primary"
              class="q-mr-sm"
              @click="onIsValid(row)"
              >{{ row.Status == 1 ? "禁用" : "启用" }}</q-btn
            >
            <q-btn-dropdown dense flat color="primary" label="其他操作">
              <q-list>
                <q-item clickable v-close-popup @click="onShowAuth(row)">
                  <q-item-section>
                    <q-item-label class="text-primary text-bold">
                      <q-icon name="vpn_key" class="q-mr-sm" />
                      <span>机构授权</span>
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup @click="onResetPassword(row)">
                  <q-item-section>
                    <q-item-label class="text-negative text-bold">
                      <q-icon name="vpn_key" class="q-mr-sm" />
                      <span>重置用户密码</span>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
        </vxe-column>
      </vxe-table>
    </q-card-section>
    <UserEdit />
    <UserAuth />
  </q-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dlg from "@/config/dialog";
import UserEdit from "./userEdit";
import UserAuth from "./userAuth";
import * as SysUserInfo from "@/api/gy/base/sysuser.js";
export default {
  components: {
    UserEdit,
    UserAuth,
  },
  data() {
    return {
      loading: false,
      filterString: "",
    };
  },
  computed: {
    ...mapGetters("appUser", ["hospID"]),
    ...mapState("SysUser", ["UserList", "UserEntity"]),
    entity: {
      get() {
        return this.UserEntity;
      },
      set(val) {
        this.$store.commit("SysUser/setUserEntity", val);
      },
    },
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("SysUser", [
      "actLoadData",
      "actCreateUser",
      "actEditSysUser",
      "actDeleteSysUser",
      "actUserResetPassword",
      "actShowAuthDlg",
    ]),
    onLoadData() {
      this.loading = true;
      this.actLoadData({
        HospID: this.hospID,
        FilterString: this.filterString,
      })
        .then((res) => {
          if (res.Code != 0) this.msg.error(res.Message);
        })
        .catch((err) => {
          this.msg.error(err.Message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //新增用户
    onAdd() {
      this.loading = true;
      this.actCreateUser({ HospID: this.hospID })
        .then(() => {
          this.visible = true;
        })
        .catch((err) => {
          this.msg.error(err.Message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //修改用户信息
    onEdit(e) {
      console.log(e);
      this.actEditSysUser(e);
    },
    onIsValid(e){
        SysUserInfo.UpateIsValid({
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    //删除用户
    onDelete(e) {
      this.dlg.confirm("是否确定要删除当前用户信息？").onOk(() => {
        this.actDeleteSysUser(e)
          .then((res) => {
            if (res.Code == 0) this.msg.success("删除用户成功");
            else this.msg.error(res.Message);
          })
          .catch((err) => {
            this.msg.error(err.Message);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    //重置用户密码
    onResetPassword(e) {
      this.dlg
        .confirm("当前用户的登录密码将重置为“123456”，是否确定？")
        .onOk(() => {
          this.loading = true;
          this.actUserResetPassword(e)
            .then((res) => {
              if (res.Code == 0) this.msg.success("用户密码重置成功！");
            })
            .catch((err) => {
              this.msg.error(err.Message);
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },
    onShowAuth(row) {
      this.actShowAuthDlg({ userInfo: row, visible: true });
    },
  },
};
</script>
