<template>
  <q-menu transition-show="scale" transition-hide="scale" fit>
    <q-list v-if="currentModules">
      <span v-for="d in currentModules.Children" :key="d.ModuleID">
        <DropDownMenuItem :dataObject="d" v-if="!d.IsHide" />
      </span>
    </q-list>
  </q-menu>
</template>

<script>
import { mapGetters } from "vuex";
import DropDownMenuItem from "./dropdownMenuItem.vue";
export default {
  name: "dropdownMenu",
  components: {
    DropDownMenuItem,
  },
  computed: {
    ...mapGetters("appUser", ["authModules", "currentSysID"]),
  },
  watch: {
    currentSysID: {
      handler() {
        this.onLoadData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      currentModules: [],
    };
  },
  created() {
    this.onLoadData();
  },
  methods: {
    onLoadData() {
      if (this.authModules) {
        var mod = this.authModules.find((v) => {
          return v.SysID == this.SystemKey;
        });
        if (mod) {
          var find = mod.Children.find((v) => {
            return v.ModuleID == this.currentSysID;
          });
          this.currentModules = find;
        }
      }
    },
  },
};
</script>