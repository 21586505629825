<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="患者名称"
          color="primary"
        />
        <q-input
          dense
          outlined
          type="date"
          v-model="BenDate"
          label="查询开始日期"
          stack-label
        />
        <q-input
          dense
          outlined
          type="date"
          v-model="EndDate"
          label="查询结束日期"
          stack-label
        />
        <q-select
          map-options
          emit-value
          outlined
          dense
          style="width:120px;"
          v-model="YuYueStatus"
          color="primary"
          label="预约状态"
          :options="[
            { label: '全部', value: -2 },
            { label: '取消预约', value: -1 },
            { label: '预约', value: 0 },
            { label: '登记', value: 1 },
            { label: '完成', value: 2 },
          ]"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmMaApptListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmMaApptListLoading" @click="onExportData">导出</q-btn> -->
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmMaApptListLoading"
          @click="onAdd"
          >新增</q-btn
        >
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmMaApptListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <!-- <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          > -->
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmMaApptshowDlg" persistent>
    <MaApptEdit @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import MaApptEdit from "./maApptEdit.vue";
import * as maAppt from "@/api/pdm/ma/maAppt";

export default {
  components: {
    MaApptEdit,
  },
  computed: {
    ...mapState("PdmMaAppt", [
      "PdmMaApptList",
      "PdmMaApptListLoading",
      "PdmMaApptEntity",
      "PdmMaApptShowDlg",
    ]),

    pdmMaApptshowDlg: {
      get() {
        return this.PdmMaApptShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaAppt/setPdmMaApptShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      YuYueStatus: -2,
      BenDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd"),
      EndDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd"),
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "PackName",
            title: "预约套餐",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "HisPatientID",
            title: "His患者ID",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "ApptName",
            title: "预约患者名称",
            sortable: true,
            align: "left",
            width: 120,
          },
          {
            field: "ApptCertNo",
            title: "身份证号",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "ApptPhone",
            title: "预约电话",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "ApptDate",
            title: "预约日期",
            sortable: true,
            align: "center",
            width: 140,
          },
          {
            field: "SchdTimeBegTime",
            title: "预约时间段开始时间",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "SchdTimeEndTime",
            title: "预约时间段结束时间",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "StatusStr",
            title: "预约状态",
            sortable: true,
            align: "center",
            width: 100,
          },
          {
            field: "CDate",
            title: "创建时间",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            title: "操作",
            width: 100,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmMaApptList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmMaAppt", [
      "actGetPdmMaApptListByPage",
      "actCreatePdmMaAppt",
      "actEditPdmMaApptData",
      "actEditPdmMaAppt",
      "actDeletePdmMaAppt",
    ]),
    onLoadData() {
      this.actGetPdmMaApptListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        BenDate: this.BenDate,
        EndDate: this.EndDate,
        Status: this.YuYueStatus,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
          this.onLoadData2();
        })
        .finally(() => {
          this.$store.commit("PdmMaAppt/setPdmMaApptListLoading", false);
          //this.PdmMaApptListLoading = false;
        });
    },
    onLoadData2() {
      maAppt
        .UpdateYuYueState({
          HospID: this.$store.getters["appUser/hospID"],
          CUser: this.$store.getters["appUser/userID"],
        })
        .then((res) => {
          if (res.Code == 0) {
            this.$store.commit("PdmBodycheckYuyue/setPdmMaApptYuYueCount", 0);
          } else {
            dlg.alert(res.Message);
          }
        });
    },
    onAdd() {
      this.actCreatePdmMaAppt({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmMaAppt(obj);
    },
    onDelete(e) {
      this.actDeletePdmMaAppt({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
