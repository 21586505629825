<template>
    <div>
        <BodycheckPaiban />
    </div>
</template>

<script>
import BodycheckPaiban from "./bodycheckPaibanView.vue";
export default {
    components: {
        BodycheckPaiban,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
