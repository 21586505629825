<template>
  <q-toolbar :style="{ 'max-width': chipListWidth }" class="q-px-sm">
    <q-chip v-for="d in barItems" :key="d.name" square removable clickable @click="onGo(d)"
      @remove="onClose(d)" :class="d.name == currentPage ? 'chipSelected' : 'chipUnselected'"
      :color="d.name == currentPage ? 'primary' : 'white'" style="border: 1px solid lightgray;">
      <q-avatar class="q-pl-xs">
        <q-icon :name="d.meta.icon" size="12px"/>
      </q-avatar>
      <div class="ellipsis">
        {{ d.meta.title }}
        <q-tooltip>{{ d.meta.title }}</q-tooltip>
      </div>
    </q-chip>

    <q-space />

    <q-btn round flat size="sm" icon="fas fa-angle-down">
      <q-menu anchor="bottom middle" self="top middle">
        <q-list dense style="min-width: 100px;color:#333333">
          <q-item v-for="d in menuItems" :key="d.name" clickable v-close-popup @click="onGo(d)">
            <q-item-section avatar>
              <q-icon size="12px" :name="d.meta.icon" />
            </q-item-section>
            <q-item-section>{{ d.meta.title }}</q-item-section>
          </q-item>
          <q-separator />
          <q-item clickable v-close-popup @click="onClear">
            <q-item-section avatar>
              <q-icon size="12px" name="fas fa-trash-alt" />
            </q-item-section>
            <q-item-section>关闭其他页面</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </q-btn>
  </q-toolbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("MultiPageBar", ["PageList", "CurrentPage"]),
    currentPage: {
      get() {
        if (this.CurrentPage)
          return this.CurrentPage;
        else
          return this.$route.name
      },
      set(val) {
        this.$store.commit("MultiPageBar/setCurrentPage", val);
      },
    },
    keepRoutes() {
      var rs = [];
       this.PageList.forEach((e) => {
        if(e.name) rs.push(e);
      });

      return rs;
    },
    chipListWidth() {
      return this.$store.getters["maxPageWidth"] - 500 + "px"
    },
    barItems() {
      var w = this.$store.getters["maxPageWidth"] - 550
      var cnt = Math.floor(w / 138)
      var list = this.keepRoutes.slice(0, cnt)
      return list
    },
    menuItems() {
      var w = this.$store.getters["maxPageWidth"] - 550
      var cnt = Math.floor(w / 138)
      var list = this.keepRoutes.slice(cnt)
      return list
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("MultiPageBar", ["actClosePage", "actClearPages"]),
    onClose(e) {
      this.$nextTick(() => {
        this.actClosePage(e).then((res) => {
          if (res) {
            this.$router.push(res);
          }
        });
      });
    },
    onClear() {
      this.actClearPages();
    },
    GetComponent(path) {
      return new Promise((resolve) => {
        const com = () => require(`@/views${path}.vue`);
        resolve(com);
      });
    },
    onGo(e) {
      this.$router.push(e.path);
    },
  },
};
</script>

<style scoped>
:deep() .q-tab__content {
  padding: 0;
}

.chipSelected {
  color: white;
  background-color: linear-gradient(-86deg, #47ABFF, #2C76FF);
  /* box-shadow: 0px 8px 8px 0px rgba(62, 154, 255, 0.24); */
  border-radius: 4px;
}

.chipUnselected {
  color: #333333;
  background-color: linear-gradient(-86deg, #dde7f0, #b4b7bd);
  border-radius: 4px;
}

:deep() .q-chip {
  max-width: 138px;
  min-width: 138px;
}
</style>
