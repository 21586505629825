<template>
    <div class="absolute-center">
         <!-- <q-btn color="primary" label="打开微信小程序" @click="onOpen" /> -->
         <div class="text-h6">
            正在跳转，请稍后...
         </div>
    </div>
</template>

<script>
import * as wxapi from "@/api/pdm/wx.js";
import dlg from '@/config/dialog'
export default {
    components: {
       
    },
    data() {
        return{
        }
    },
    created(){
        this.onOpen()
    },
    methods: {
        onOpen() {
           var url=window.location.href;
           var Params=new URL(url).searchParams;
           var code=Params.get('code');
           var state=Params.get('state');
            wxapi.GetWXFuWuHaoOpenID({
                WXFuWuHao_Code:code,
                QueryStr:state
            }).then(res=>{
                if (res.Code==0) {
                    location.href = res.Data;
                }else{
                    dlg.alert(res.Message)
                }
            })
        },
    },
};
</script>
