<template>
    <div v-if="dataObject" style="color:#333333">
        <q-expansion-item class="rounded-borders " v-if="childrens.length > 0" expand-separator>
            <template v-slot:header>
                <q-item-section avatar>
                    <q-icon :name="dataObject.RouteIcon" size="16px" />
                </q-item-section>
                <q-item-section>
                    <div class="ellipsis">{{ dataObject.ModelName }}</div>
                </q-item-section>
            </template>
            <q-card class="bg-grey-2">
                <q-card-section style="padding:6px">
                    <SlideMenuItem v-for="d in childrens" :dataObject="d" :key="d.ModuleID"></SlideMenuItem>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <q-item v-else clickable :to="dataObject.RoutePath" @click="onClickMenu(dataObject)"
            :active="dataObject.ModuleID == currentModuleID" active-class="ItemSelected text-bold">
            <q-item-section avatar class="q-pr-none">
                <q-icon :name="dataObject.RouteIcon" size="16px" />
            </q-item-section>
            <q-item-section>
                <div class="ellipsis">{{ dataObject.ModelName }}</div>
            </q-item-section>
        </q-item>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "SlideMenuItem",
    props: {
        dataObject: {},
    },
    computed: {
        ...mapGetters("appUser", ["currentModuleID"]),
        childrens() {
            if(this.dataObject.Children) return this.dataObject.Children.filter(v => !v.IsHide)
            else return []
        }
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions("appUser", ["actSetCurrentModuleID"]),
        onClickMenu(e) {
            this.actSetCurrentModuleID(e.ModuleID);
        },
    },
};
</script>

<style scoped>
.ItemSelected {
    /* width: 200px; */
    margin-left: 6px;
    margin-right: 6px;
    height: 42px;
    background: linear-gradient(-86deg, #47ABFF, #2C76FF);
    box-shadow: 0px 8px 8px 0px rgba(62, 154, 255, 0.24);
    border-radius: 4px;
    border-width: 0px;
    font-size: 14px;
    font-weight: 400;
    color: white;
}

:deep() .q-item__section--avatar{
    min-width: 24px;
}
</style>